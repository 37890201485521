function AuditLogsLogo(props) {
  return (
    <svg
      width={548}
      height={548}
      viewBox='0 0 548 548'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M527.722 427.138c4.262-4.262 4.262-11.173 0-15.435-4.262-4.262-11.172-4.262-15.434 0l15.434 15.435zM26.316 133.787l-7.81-7.624 7.81 7.624zM398.17 519.041H45.841v21.828h352.328v-21.828zm-368.7-16.371V152.846H7.643V502.67H29.47zm482.819-90.967L409.745 514.246l15.434 15.435 102.543-102.543-15.434-15.435zM34.126 141.41L136.9 36.13l-15.62-15.248L18.506 126.163l15.62 15.247zM45.84 519.041c-9.042 0-16.371-7.329-16.371-16.371H7.642c0 21.097 17.102 38.199 38.199 38.199v-21.828zm352.328 21.828a38.195 38.195 0 0027.01-11.188l-15.434-15.435a16.375 16.375 0 01-11.576 4.795v21.828zm-368.7-388.023a16.37 16.37 0 014.657-11.436l-15.62-15.247a38.197 38.197 0 00-10.864 26.683H29.47z'
        fill='#000'
      />
      <path
        d='M150.986 424.813h352.521c15.069 0 27.285-12.216 27.285-27.285V45.008c0-15.07-12.216-27.285-27.285-27.285H150.986c-15.069 0-27.285 12.216-27.285 27.285v352.52c0 15.069 12.216 27.285 27.285 27.285z'
        fill='#fff'
      />
      <path
        d='M503.507 413.899H150.986v21.828h352.521v-21.828zm-368.892-16.371V45.008h-21.828v352.52h21.828zm385.263-352.52v352.52h21.828V45.008h-21.828zM150.986 28.636h352.521V6.809H150.986v21.828zm390.72 16.37c0-21.096-17.103-38.198-38.199-38.198v21.828c9.041 0 16.371 7.33 16.371 16.37h21.828zM150.986 413.9c-9.041 0-16.371-7.329-16.371-16.371h-21.828c0 21.097 17.102 38.199 38.199 38.199v-21.828zm352.521 21.828c21.096 0 38.199-17.102 38.199-38.199h-21.828c0 9.042-7.33 16.371-16.371 16.371v21.828zM134.615 45.007c0-9.04 7.33-16.37 16.371-16.37V6.809c-21.097 0-38.199 17.102-38.199 38.199h21.828z'
        fill='#000'
      />
      <path
        d='M121.003 41.986L17.208 143.085c.45 101.548 1.348 312.462 1.348 343.735 0 31.274 16.176 41.788 24.264 43.136H409.47l99.75-103.795H160.094c-31.273 0-39.091-20.669-39.091-31.003V41.986z'
        fill='#000'
        stroke='#000'
        strokeWidth={2.69596}
      />
      <path
        d='M327.28 65.484c-15.428 0-28.052 12.624-28.052 28.053 0 5.05 1.402 9.818 3.927 14.026H259.11c-20.198 0-36.468 16.271-36.468 36.469v185.43c0 20.198 16.27 36.468 36.468 36.468h136.622c20.198 0 36.468-16.27 36.468-36.468v-185.43c0-20.198-16.27-36.469-36.468-36.469h-44.324c2.525-4.207 3.928-8.976 3.928-14.026 0-15.429-12.624-28.053-28.053-28.053h-.003zm0 16.832c6.172 0 11.222 5.05 11.222 11.221 0 6.172-5.05 11.221-11.222 11.221-6.171 0-11.221-5.05-11.221-11.22 0-6.172 5.05-11.222 11.221-11.222zm-68.172 42.079h13.467v16.832c0 1.963.842 4.208 2.525 5.891 1.683 1.683 3.647 2.525 5.891 2.525h92.577c4.769 0 8.416-3.647 8.416-8.416v-16.832h13.468c10.94 0 19.637 8.696 19.637 19.637v185.43c0 10.94-8.696 19.637-19.637 19.637H259.113c-10.941 0-19.637-8.696-19.637-19.637v-185.43c0-10.94 8.696-19.637 19.637-19.637h-.005zm23.286 45.165a14.03 14.03 0 00-14.027 14.027c0 3.719 1.478 7.286 4.108 9.916a14.023 14.023 0 0019.837 0 14.024 14.024 0 00-9.918-23.943zm47.689 5.611c-4.769 0-8.416 3.647-8.416 8.416s3.647 8.415 8.416 8.415h50.495c4.769 0 8.416-3.646 8.416-8.415 0-4.769-3.647-8.416-8.416-8.416h-50.495zm-47.689 58.071a14.03 14.03 0 00-14.027 14.027 14.03 14.03 0 0023.945 9.918 14.03 14.03 0 00-9.918-23.945zm47.689 5.047c-4.769 0-8.416 3.647-8.416 8.416s3.647 8.416 8.416 8.416h50.495c4.769 0 8.416-3.647 8.416-8.416s-3.647-8.416-8.416-8.416h-50.495zm-25.192 43.246c-2.685.033-5.315 1.325-7.068 3.604l-15.71 23.846-9.818-12.624c-2.805-3.647-8.137-4.494-11.784-1.689-3.648 2.806-4.486 8.138-1.681 11.785l16.551 22.164c1.683 2.244 4.208 3.364 7.013 3.364h.281c2.805 0 5.33-1.683 7.013-3.928l22.161-33.385c2.525-3.927 1.401-8.974-2.246-11.779a8.464 8.464 0 00-4.712-1.358zm25.192 19.872c-4.769 0-8.416 3.647-8.416 8.416s3.647 8.416 8.416 8.416h50.495c4.769 0 8.416-3.647 8.416-8.416s-3.647-8.416-8.416-8.416h-50.495z'
        fill='#000'
      />
    </svg>
  );
}

export default AuditLogsLogo;
