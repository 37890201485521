function SSOLogo(props) {
  return (
    <svg
      width={548}
      height={548}
      viewBox='0 0 548 548'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M527.722 427.136c4.262-4.262 4.262-11.172 0-15.434-4.262-4.262-11.172-4.262-15.434 0l15.434 15.434zM26.316 133.785l-7.81-7.624 7.81 7.624zM398.17 519.04H45.841v21.828h352.328V519.04zm-368.7-16.371V152.845H7.643v349.824H29.47zm482.819-90.967L409.745 514.245l15.434 15.434 102.543-102.543-15.434-15.434zM34.126 141.409L136.9 36.129 121.28 20.88 18.506 126.16l15.62 15.248zM45.84 519.04c-9.042 0-16.371-7.33-16.371-16.371H7.642c0 21.096 17.102 38.199 38.199 38.199V519.04zm352.328 21.828a38.195 38.195 0 0027.01-11.189l-15.434-15.434a16.375 16.375 0 01-11.576 4.795v21.828zm-368.7-388.023a16.37 16.37 0 014.657-11.436l-15.62-15.248a38.2 38.2 0 00-10.864 26.684H29.47z'
        fill='#000'
      />
      <path
        d='M150.986 424.812h352.521c15.069 0 27.285-12.216 27.285-27.285V45.007c0-15.07-12.216-27.285-27.285-27.285H150.986c-15.069 0-27.285 12.215-27.285 27.285v352.52c0 15.069 12.216 27.285 27.285 27.285z'
        fill='#fff'
      />
      <path
        d='M503.507 413.898H150.986v21.828h352.521v-21.828zm-368.892-16.371V45.007h-21.828v352.52h21.828zm385.263-352.52v352.52h21.828V45.007h-21.828zM150.986 28.636h352.521V6.808H150.986v21.828zm390.72 16.37c0-21.096-17.103-38.198-38.199-38.198v21.828c9.041 0 16.371 7.33 16.371 16.37h21.828zm-390.72 368.892c-9.041 0-16.371-7.329-16.371-16.371h-21.828c0 21.097 17.102 38.199 38.199 38.199v-21.828zm352.521 21.828c21.096 0 38.199-17.102 38.199-38.199h-21.828c0 9.042-7.33 16.371-16.371 16.371v21.828zM134.615 45.006c0-9.04 7.33-16.37 16.371-16.37V6.808c-21.097 0-38.199 17.102-38.199 38.199h21.828z'
        fill='#000'
      />
      <path
        d='M121.003 41.986L17.208 143.084c.45 101.548 1.348 312.463 1.348 343.736 0 31.273 16.176 41.787 24.264 43.135H409.47l99.75-103.794H160.094c-31.273 0-39.091-20.669-39.091-31.004V41.986z'
        fill='#000'
        stroke='#000'
        strokeWidth={2.69596}
      />
      <path
        d='M185.879 280.7a56.428 56.428 0 0041.172 18.077l154.229.277 28.425-.441a84.883 84.883 0 0042.272-11.024 67.462 67.462 0 00-27.558-122.854 69.947 69.947 0 00-19.345 0 6.888 6.888 0 01-7.165-3.912 81.46 81.46 0 00-116.692-34.571 81.466 81.466 0 00-37.302 55.293 6.945 6.945 0 01-7.825 5.512 57.027 57.027 0 00-22.046.827 56.275 56.275 0 00-28.165 92.816z'
        stroke='#000'
        strokeWidth={20.3464}
      />
      <path
        d='M327.946 233.482h63.568a4.518 4.518 0 000-8.996h-.761v-9.435a4.519 4.519 0 00-8.996 0v9.435h-17.472v-9.435a4.514 4.514 0 00-4.497-4.942 4.514 4.514 0 00-4.498 4.942v9.435h-27.349.002a34.514 34.514 0 00-9.795-19.991 34.867 34.867 0 00-49.019 0 34.625 34.625 0 1058.813 28.867l.004.12zm-52.296 13.633l-.002.002a25.632 25.632 0 0118.133-43.741 25.632 25.632 0 0118.118 7.502 25.63 25.63 0 01.013 36.239 25.317 25.317 0 01-18.111 7.396 25.718 25.718 0 01-18.153-7.437l.002.039z'
        fill='#000'
        stroke='#000'
        strokeWidth={7}
      />
    </svg>
  );
}

export default SSOLogo;
